<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
 <div v-else>
   <div class="page-progress-wrap">
     <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.title : '' }}</h3>
     <div class="progress-box">
       <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[0].image_link: ''" alt="">
       <span>Input</span>
     </div>
     <div class="progress-box">
       <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[1].image_link: ''" alt="">
       <span>Confirm</span>
     </div>
     <div class="done-box">
       <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[2].image_link: ''" alt="">
       <span>Done</span>
     </div>
   </div>
   <div class="support-history-box walet-box">
     <form id="form">
       <div class="form-group">
         <label class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.title : '' }}</label>
         <div class="prblem-wrapper">
           <label class="problem" v-for="(wallets,index) in dataDetails.wallets">
             <input type="radio" @click="setWallet(wallets.id)" name="walletTypeId" :value="wallets.id" :checked="index===0"
                    :data-option_id="wallets.option_id">
             <div class="problem-box">
               {{ !_.isEmpty(dataDetails) ? wallets.name : '' }}
             </div>
           </label>
         </div>
       </div>
       <button type="button" @click="submit(dataDetails.total_point)" class="btn justify-center" data-language="1">
         {{ !_.isEmpty(dataDetails) ? dataDetails.next_btn : '' }} <span class="arrow-icon"></span>
       </button>
       <br>
       <span style="color: red;display: block;text-align: center"></span>
     </form>
   </div>
   <div class="transaction-modal-open" id="top_up_capping_modal" v-if="cappingError">
     <div class="transaction-modal-inner">
       <div class="transaction-modal-close top_up_cap_modal_close" @click="cappingError=false">×</div>
       <div class="transaction-modal-content">
         <div class="ofailed-icon-box">
           <img :src=" !_.isEmpty(remainingBalanceData) ? remainingBalanceData.capping_failed_image: ''"
                alt="">
         </div>
         <h4>{{ !_.isEmpty(remainingBalanceData) ? remainingBalanceData.topup_cap_modal_title : '' }}</h4>
         <p>{{ !_.isEmpty(remainingBalanceData) ? remainingBalanceData.topup_cap_modal_line : '' }}</p>
         <p id="top_up_capping_message"></p>
         <p>{{ !_.isEmpty(remainingBalanceData) ? remainingBalanceData.topup_cap_modal_line_3 : '' }}</p>
         <p class="border-0">{{ !_.isEmpty(remainingBalanceData) ? remainingBalanceData.topup_cap_modal_line_4 : '' }}</p>
         <button class="transaction-btn top_up_cap_modal_close" @click="cappingError=false">Ok</button>
<!--        <button class="ticket-btn ticket-close">{{__('lang.')}}</button>-->
       </div>
     </div>
   </div>
 </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'

export default {
  name: 'walletSelectPage',
  data () {
    return {
      apiUrl: 'content/',
      checkRemainingBalanceApi: 'get-remaining-balance',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      service: '',
      is_loading: true,
      packagePrice: sessionStorage.getItem('packagePrice') ?? '',
      msisdn: localStorage.getItem('msisdn') ?? '',
      languageId: sessionStorage.getItem('language') ?? '1',
      operatorId: localStorage.getItem('operatorId'),
      sessionId: sessionStorage.getItem('sessionId'),
      remainingBalanceData: '',
      walletId: null,
      cappingError: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      if (this.service === 'talktime_topup') {
        this.apiUrl += 'talktime-wallet-select-page'
      } else {
        this.apiUrl += 'wallet-select-page'
      }
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, '', { params: { price: this.packagePrice, msisdn: this.msisdn, languageId: this.languageId, operatorId: this.operatorId } })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getRemainingBalance () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.checkRemainingBalanceApi, { msisdn: this.msisdn, languageId: this.languageId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setWallet (id) {
      sessionStorage.setItem('walletId', id)
      this.walletId = id
    },
    async submit (point) {
      if (!this.walletId) {
        sessionStorage.setItem('walletId', '-2')
        this.walletId = '-2'
      }
      if (this.operatorId === '1' && this.walletId === '-2') {
        await this.getRemainingBalance().then(response => {
          this.remainingBalanceData = response.data
          if (this.remainingBalanceData.remaining_balance > 0) {
            this.$router.push({ name: 'wallet-confirmation-page', params: { service: this.service } })
          } else {
            this.cappingError = true
          }
        })
      } else {
        this.$router.push({ name: 'wallet-confirmation-page', params: { service: this.service } })
      }
      sessionStorage.setItem('totalPoint', point)
    }
  }
}
</script>

<style scoped>

</style>
